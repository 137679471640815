import React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import { StackOnBreadcrumbs } from '../atoms';
import { LayoutProvider, Wrapper } from '../components/layout';
import { OurCompany } from '../components/sections';
import { useViewPortSize } from '../hooks';
import { stackStyled } from '../theme';
import { IHomePage, query } from '.';

const queryAboutUs = graphql`
query AboutUs {
  strapiOurCompany {
    title
    subTitle
    videoURL
    card {
      id
      rightTitle
      rightText
      rightImage {
        url
        ext
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 545
              transformOptions: {fit: COVER}
              breakpoints: 427
              sizes: "max-width: 585px"
            )
          }
        }
      }
      leftTitle
      leftText
      leftImage {
        url
        ext
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 545
              transformOptions: {fit: COVER}
              breakpoints: 427
              sizes: "max-width: 585px"
            )
          }
        }
      }
    }
  }
}
`

export interface IDataAboutUs {
  strapiOurCompany: {
    title: string;
    subTitle: string;
    videoURL: string;
    card: {
      id: number;
      rightTitle: string;
      rightText: string;
      leftTitle: string;
      leftText: string;
      rightImage: ImageDataLike;
      leftImage: ImageDataLike;
    }
  }
}

const MainWrapper = stackStyled(Wrapper, () => ({
  display: "flex",
  justifyContent: "center",
  marginLeft: "auto",
  marginRight: "auto",
  overflowX: "hidden",
  textAlign: "center",
  backgroundColor: "none",
}));

const ContainerBreadCrumbs = stackStyled(
  "div",
  (props: { isMobile: boolean, isTablet: boolean }) => ({
    position: "absolute",
    top: props.isMobile ? "10px" : "30px",
    left: props.isTablet ? '50px' : props.isMobile ? '20px' : "70px",
    width: "380px",
    backgroundColor: "transparent",
  })
);

export const AboutUs: React.FC<PageProps> = (props: PageProps) => {

  const data = useStaticQuery<IDataAboutUs>(queryAboutUs);

  // HOOKS
  const [isMobile, isTablet] = useViewPortSize();
  // STATE

  // HANLDERS && FUNCTIONS

  // RENDER

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper noScroll>
        <ContainerBreadCrumbs isMobile={isMobile} isTablet={isTablet}>
          <StackOnBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'About us', href: '/about-us' },
            ]}
          />
        </ContainerBreadCrumbs>
        <OurCompany 
          title={data.strapiOurCompany.title} 
          subTitle={data.strapiOurCompany.subTitle} 
          videoURL={data.strapiOurCompany.videoURL} 
          card={{
          id: data.strapiOurCompany.card.id,
          rightTitle: data.strapiOurCompany.card.rightTitle,
          rightText: data.strapiOurCompany.card.rightText,
          leftTitle: data.strapiOurCompany.card.leftTitle,
          leftText: data.strapiOurCompany.card.leftText,
          rightImage: data.strapiOurCompany.card.rightImage,
          leftImage: data.strapiOurCompany.card.leftImage
        }} />
      </MainWrapper>
    </LayoutProvider>
  );
};

export default AboutUs;
